var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { isEmpty } from 'lodash';
let EmailProspectInfoDropdown = class EmailProspectInfoDropdown extends Vue {
    validateEmails() {
        return true;
    }
    getEmailsProcessedTotal() {
        if (isEmpty(this.statsEmails))
            return 0;
        return (this.statsEmails.valid_emails +
            this.statsEmails.invalid_emails +
            this.statsEmails.maybe_emails);
    }
    get validationButtonLabel() {
        return this.loading
            ? 'Solicitando...'
            : !this.canRequestValidation
                ? 'Validação solicitada'
                : 'Solicitar validação';
    }
    get canRequestValidation() {
        if (this.disableValidationEmail)
            return false;
        if (this.statusValidationsEmails === 'not initialized' ||
            this.statusValidationsEmails === 'finished') {
            return true;
        }
        return false;
    }
};
__decorate([
    Prop({ default: () => { } })
], EmailProspectInfoDropdown.prototype, "statsEmails", void 0);
__decorate([
    Prop({ default: false })
], EmailProspectInfoDropdown.prototype, "loading", void 0);
__decorate([
    Prop({ default: false })
], EmailProspectInfoDropdown.prototype, "disableValidationEmail", void 0);
__decorate([
    Prop({ default: false })
], EmailProspectInfoDropdown.prototype, "validationSending", void 0);
__decorate([
    Prop({ default: '' })
], EmailProspectInfoDropdown.prototype, "statusValidationsEmails", void 0);
__decorate([
    Emit('validateEmails')
], EmailProspectInfoDropdown.prototype, "validateEmails", null);
EmailProspectInfoDropdown = __decorate([
    Component({
        name: 'EmailProspectInfoDropdown',
    })
], EmailProspectInfoDropdown);
export default EmailProspectInfoDropdown;

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import AccountEffortInfoDropdown from 'components/AccountEffortInfoDropdown.vue';
import PhonesProspectInfoDropdown from 'components/PhonesProspectInfoDropdown.vue';
import EmailProspectInfoDropdown from 'components/EmailPropspectinInfoDropdown.vue';
import { parseInt } from 'lodash';
let CompaniesStats = class CompaniesStats extends Vue {
    constructor() {
        super(...arguments);
        this.showAccountEffortDropdown = true;
    }
    get progressPercentContacted() {
        return Math.round((this.stats.prospectings * 100) / this.stats.contacts);
    }
    get progressPercentInterested() {
        return Math.round((this.stats.interested * 100) / this.stats.contacts);
    }
    get progressPercentSuccess() {
        //return Math.abs((this.stats.successes * 100) / this.totalSuccess);
        return Math.round((this.stats.successes * 100) / this.stats.contacts);
    }
    get totalProgressContacts() {
        if (this.stats.contacts >= 0) {
            const totalProgress = Math.round(((this.stats.future_interest +
                this.stats.discarded +
                this.stats.successes) *
                100) /
                this.stats.contacts);
            if (isNaN(totalProgress)) {
                return 0;
            }
            return parseInt(totalProgress.toString());
        }
        else {
            return 0;
        }
    }
    get emailsBagdge() {
        if (this.statsEmails.valid_emails > 99) {
            return '99+';
        }
        else {
            return this.statsEmails.valid_emails;
        }
    }
    get phonesBadge() {
        if (this.statsPhones.valid_phones > 99) {
            return '99+';
        }
        else {
            return this.statsPhones.valid_phones;
        }
    }
    toggleAnimationIconClass(refName, className) {
        //@ts-ignore
        this.$refs[refName].classList.add(className);
        //@ts-ignore
        const parentEl = this.$refs[refName].parentElement;
        if (refName !== 'icon-diamond') {
            parentEl.children[parentEl.children.length - 1].children[0].classList.add('text--animated');
        }
        setTimeout(() => {
            //@ts-ignore
            this.$refs[refName].classList.remove(className);
            parentEl.children[parentEl.children.length - 1].children[0].classList.remove('text--animated');
        }, 2000);
    }
    onAnimateStatusChanged(status, oldStatus) {
        if (status) {
            switch (status) {
                case 'not_started':
                    return;
                case 'started':
                    this.toggleAnimationIconClass('icon-lightening', 'icon-lightening--animation');
                    return;
                case 'interested':
                    this.toggleAnimationIconClass('icon-fire', 'icon-fire--animation');
                    return;
                case 'success':
                    this.toggleAnimationIconClass('icon-thumbs-up', 'icon-thumbs-up--animation');
                    this.toggleAnimationIconClass('icon-diamond', 'icon-diamond--animation');
                    return;
                case 'discarded':
                    this.toggleAnimationIconClass('icon-diamond', 'icon-diamond--animation');
                    return;
                case 'future_interest':
                    this.toggleAnimationIconClass('icon-diamond', 'icon-diamond--animation');
                    return;
                default:
                    return '';
            }
        }
    }
    changeShowAccountEffortDropdown(value) {
        this.showAccountEffortDropdown = value;
    }
    validatePhone() { }
    validateEmails() { }
    openEmailsInfoDropdown() {
        return true;
    }
    openPhonesInfoDropdown() {
        return true;
    }
};
__decorate([
    Prop({ default: () => [] })
], CompaniesStats.prototype, "companies", void 0);
__decorate([
    Prop({ default: () => { } })
], CompaniesStats.prototype, "stats", void 0);
__decorate([
    Prop({ default: null })
], CompaniesStats.prototype, "statusValidationsPhones", void 0);
__decorate([
    Prop({ default: null })
], CompaniesStats.prototype, "phonesStartedHowManyDaysAgo", void 0);
__decorate([
    Prop({ default: '' })
], CompaniesStats.prototype, "statusValidationsEmails", void 0);
__decorate([
    Prop({ default: false })
], CompaniesStats.prototype, "showPhonesValidationButton", void 0);
__decorate([
    Prop({ default: () => { } })
], CompaniesStats.prototype, "statusResponse", void 0);
__decorate([
    Prop({ default: () => { } })
], CompaniesStats.prototype, "statsPhones", void 0);
__decorate([
    Prop({ default: () => { } })
], CompaniesStats.prototype, "statsEmails", void 0);
__decorate([
    Prop({ default: '' })
], CompaniesStats.prototype, "animateStatus", void 0);
__decorate([
    Prop({ default: 0 })
], CompaniesStats.prototype, "totalContacted", void 0);
__decorate([
    Prop({ default: 0 })
], CompaniesStats.prototype, "totalInterested", void 0);
__decorate([
    Prop({ default: 0 })
], CompaniesStats.prototype, "totalSuccess", void 0);
__decorate([
    Prop({ default: false })
], CompaniesStats.prototype, "loadingValidate", void 0);
__decorate([
    Prop({ default: false })
], CompaniesStats.prototype, "disableValidationPhone", void 0);
__decorate([
    Prop({ default: false })
], CompaniesStats.prototype, "disableValidationEmail", void 0);
__decorate([
    Prop({ default: false })
], CompaniesStats.prototype, "validationSending", void 0);
__decorate([
    Prop({ default: false })
], CompaniesStats.prototype, "noFoundInfoset", void 0);
__decorate([
    Watch('animateStatus')
], CompaniesStats.prototype, "onAnimateStatusChanged", null);
__decorate([
    Emit('validatePhone')
], CompaniesStats.prototype, "validatePhone", null);
__decorate([
    Emit('validateEmails')
], CompaniesStats.prototype, "validateEmails", null);
__decorate([
    Emit('openEmailsInfoDropdown')
], CompaniesStats.prototype, "openEmailsInfoDropdown", null);
__decorate([
    Emit('openPhonesInfoDropdown')
], CompaniesStats.prototype, "openPhonesInfoDropdown", null);
CompaniesStats = __decorate([
    Component({
        components: {
            AccountEffortInfoDropdown,
            PhonesProspectInfoDropdown,
            EmailProspectInfoDropdown,
        },
    })
], CompaniesStats);
export default CompaniesStats;

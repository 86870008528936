var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
let PhonesProspectInfoDropdown = class PhonesProspectInfoDropdown extends Vue {
    validatePhone() {
        return true;
    }
    get validationButtonLabel() {
        if (this.loading) {
            return 'Solicitando...';
        }
        else if (this.alreadyRequested) {
            return 'Validação solicitada';
        }
        else {
            return 'Solicitar validação';
        }
    }
    disableValidationPhoneButton() {
        return (this.loading ||
            this.companies.length === 0 ||
            !this.statusValidationsPhones ||
            (this.alreadyRequested && this.startedLessThenThreeDaysAgo));
    }
    get alreadyRequested() {
        return ['requested', 'sended'].includes(this.statusValidationsPhones);
    }
    get startedLessThenThreeDaysAgo() {
        return this.phonesStartedHowManyDaysAgo < 3;
    }
};
__decorate([
    Prop({ default: () => [] })
], PhonesProspectInfoDropdown.prototype, "companies", void 0);
__decorate([
    Prop({ default: () => { } })
], PhonesProspectInfoDropdown.prototype, "statsPhones", void 0);
__decorate([
    Prop({ default: false })
], PhonesProspectInfoDropdown.prototype, "loading", void 0);
__decorate([
    Prop({ default: false })
], PhonesProspectInfoDropdown.prototype, "showPhonesValidationButton", void 0);
__decorate([
    Prop({ default: null })
], PhonesProspectInfoDropdown.prototype, "statusValidationsPhones", void 0);
__decorate([
    Prop({ default: null })
], PhonesProspectInfoDropdown.prototype, "phonesStartedHowManyDaysAgo", void 0);
__decorate([
    Emit('validatePhone')
], PhonesProspectInfoDropdown.prototype, "validatePhone", null);
PhonesProspectInfoDropdown = __decorate([
    Component({
        name: 'PhonesProspectInfoDropdown',
    })
], PhonesProspectInfoDropdown);
export default PhonesProspectInfoDropdown;
